import { useQuery } from "react-query";
import ProductApi from "../api/ProductApi";
import CategoryApi from "../api/CategoryApi";

export const useGetProducts = () => {
  const getProducts = async () => {
    const response = await ProductApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("products", () => getProducts());
};

export const useGetProductReviews = (func) => {
  const getProducts = async () => {
    const response = await ProductApi.GetProductReview();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    if (func) {
      func(response.data.data);
    }

    return response.data.data;
  };

  return useQuery("products", () => getProducts());
};

export const useGetProductCategories = () => {
  const getProductCategory = async () => {
    const response = await CategoryApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("products-categories", () => getProductCategory());
};

export const useGetProductTypes = () => {
  const getTypes = async () => {
    const response = await ProductApi.GetProductTypes();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("products-types", () => getTypes());
};

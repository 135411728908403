import create from "zustand";
import { persist } from "zustand/middleware";
// import { devtools } from "zustand/middleware";
import { decrypt, encrypt } from "../utils/Encrypt";

const user_default_data = {
  _id: null,
  email: null,
  name: null,
  first_name: null,
  last_name: null,
  isLoggedIn: false,
  role_id: null,
};

const useUserStore = create(
  persist(
    (set, get) => ({
      // states
      user: user_default_data,
      user_addresses: [],
      // difference between this two is route menu is menu that exist in sidebar layout
      route_permissions: [],
      // route permission is route permission in router
      route_menu: [],

      // methods
      setUser: (payload) => {
        const data = {
          _id: payload._id,
          email: payload.email,
          name: payload.name,
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.role_id._id,
          isLoggedIn: true,
        };
        set((currentState) => ({ ...currentState, user: data }));
      },
      resetUser: () => {
        set({ user: user_default_data, route_permissions: [], route_menu: [], user_addresses: [] });
      },
      setRoutePermissions: (payload) => {
        set((currentState) => ({ ...currentState, route_permissions: payload }));
      },
      setRouteMenu: (payload) => {
        set((currentState) => ({ ...currentState, route_menu: payload }));
      },
      setUserAddresses: (payload) => {
        set((currentState) => ({ ...currentState, user_addresses: payload }));
      },
    }),
    {
      name: "user",
      serialize: (state) => {
        return encrypt(JSON.stringify(state));
      },
      deserialize: (state) => {
        const decryptedData = decrypt(state);
        return JSON.parse(decryptedData);
      },
    }
  )
);

export default useUserStore;

import Api from "./Api";

export default {
  Get() {
    return Api().get("/quiz-answer");
  },
  Details(id) {
    return Api().get(`/quiz-answer/${id}`);
  },
  Update(data) {
    return Api().post(`/quiz-answer/edit`, data);
  },
  Add(data) {
    return Api().post(`/quiz-answer`, data);
  },
  Delete(data) {
    return Api().delete(`/quiz-answer`, { data: data });
  },
};

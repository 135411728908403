import Api from "./Api";

export default {
  Get() {
    return Api().get("/category");
  },
  Update(data) {
    return Api().post(`/category/edit`, data);
  },
  Add(data) {
    return Api().post(`/category`, data);
  },
  Delete(data) {
    return Api().delete(`/category`, { data: data });
  },
};

import { useQuery } from "react-query";
import QuizApi from "../api/QuizApi";

export const useGetQuiz = () => {
  const getQuiz = async () => {
    const response = await QuizApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("quiz", () => getQuiz());
};

import Api from "./Api";

export default {
  Get() {
    return Api().get("/product");
  },
  GetProductReview() {
    return Api().get("/review");
  },
  GetProductTypes() {
    return Api().get("/product-type");
  },
  AddProductTypes(data) {
    return Api().post("/product-type", data);
  },
  UpdateProductTypes(data) {
    return Api().post("/product-type/edit", data);
  },
  UpdateProductReview(data) {
    return Api().post("/review/edit", data);
  },
  DeleteProductReviews(data) {
    return Api().delete(`/review`, { data: data });
  },
  Details(id) {
    return Api().get(`/product/${id}`);
  },
  Update(data) {
    return Api().post("/product/edit", data);
  },
  Add(data) {
    return Api().post(`/product`, data);
  },
  DeleteProductTypes(data) {
    return Api().delete(`/product-type`, { data: data });
  },
  Delete(data) {
    return Api().delete(`/product`, { data: data });
  },
};

import React, { useEffect, useState } from "react";

export const AppConfig = () => {
  const [scale] = useState(13);

  useEffect(() => {
    document.documentElement.style.fontSize = scale + "px";
  }, [scale]);

  return <></>;
};

import { useQuery } from "react-query";
import RolesApi from "../api/RolesApi";

export const useGetRole = ({ filter_super_admin }) => {
  const getRole = async () => {
    const response = await RolesApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    if (filter_super_admin) {
      return response.data.data.filter((role) => role._id !== process.env.REACT_APP_SUPER_ADMIN_ID);
    } else {
      return response.data.data.filter((role) => role._id);
    }
  };
  return useQuery("role", () => getRole());
};

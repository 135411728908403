// Hooks
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// components
import Layout from "./components/Layout";
import PermissionAuth from "./components/PermissionAuth";
import Login from "./pages/Login";
import pagesList from "./routes";
import Dashboard from "./pages/Dashboard";
import Cookies from "js-cookie";
import useUserStore from "./stores/userStore";

const App = () => {
  const location = useLocation();
  const userStore = useUserStore();

  // to check token if not remove user store
  useEffect(() => {
    const cookies = Cookies.get("token");
    if (!cookies) {
      userStore.resetUser();
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      {/* default route */}
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/login" element={<Login />} />

      {/* route needed permissions */}
      <Route element={<PermissionAuth />}>
        <Route path="/dashboard" element={<Layout />}>
          <Route index element={<Dashboard />}></Route>
          {pagesList.map((page, index) => {
            const removeDashboardPrefix = page.route.split("/")[2];
            const findPermissionRoute = userStore.route_permissions.find((permission) => permission?.page_id?.route === page.route);
            return (
              findPermissionRoute?.actions.view && (
                <Route key={index * 2} path={removeDashboardPrefix}>
                  <>
                    <Route index element={<page.component permissions={findPermissionRoute?.actions} />} />
                    {page.hasChild &&
                      page.childs.map((child, childIdx) => {
                        let removePrefix = child?.route.split(removeDashboardPrefix)[1].split("/");
                        removePrefix.shift();
                        let finalPath = removePrefix.join("/");
                        let givenAccess = (findPermissionRoute?.actions.create && child.access === "create") || (findPermissionRoute?.actions.update && child.access === "update");
                        return givenAccess && <Route key={childIdx * 68} path={finalPath} element={<child.component />} />;
                      })}
                  </>
                </Route>
              )
            );
          })}
        </Route>
      </Route>

      {/* catch no match route */}
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default App;

import { useQuery } from "react-query";

import PagesApi from "../api/PagesApi";

export const useGetPage = () => {
  const getPage = async () => {
    const response = await PagesApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };
  return useQuery("pages", () => getPage());
};

export const useGetPageMenu = () => {
  const getMenuPage = async () => {
    const response = await PagesApi.GetMenu();
    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };
  return useQuery("pages-menu", () => getMenuPage(), { refetchOnWindowFocus: false });
};
